



























import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src

@Component({
  components: {
    Layout,
  },
})
export default class News extends Vue {
  data() {
    return {
      n1: require("@/assets/news/img/n1.png"),
    };
  }
}
